@charset "UTF-8";
:root {
  --font-family-sans: "Untitled Sans", serif;
  --font-family-mono: "iA Writer Duospace", serif, "IBM Plex Mono", Menlo, monospace;
  --ken: 12px;
  --blue-gray: #415264;
  --yellow: #e1fa4d;
  --red: #ff4c43;
  --white: #ffffff;
  --gray-050: rgba(0, 0, 0, 0.03);
  --gray-100: rgba(0, 0, 0, 0.1);
  --gray-200: rgba(0, 0, 0, 0.2);
  --gray-300: rgba(0, 0, 0, 0.3);
  --gray-400: rgba(0, 0, 0, 0.4);
  --gray-500: rgba(0, 0, 0, 0.5);
  --gray-600: rgba(0, 0, 0, 0.6);
  --gray-700: rgba(0, 0, 0, 0.7);
  --gray-800: rgba(0, 0, 0, 0.84);
  --gray-900: rgba(0, 0, 0, 0.9);
  --black: #000000; }

@media (color-gamut: p3) {
  @supports (color: color(display-p3 0 0 0/1)) {
    :root {
      --yellow: color(display-p3 1 1 0.26);
      --red: color(display-p3 1 0.3 0.26); } } }

@font-face {
  font-family: "Untitled Sans";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/untitled-sans-web-regular.eot") format("embedded-opentype"), url("../../fonts/untitled-sans-web-regular.woff2") format("woff2"), url("../../fonts/untitled-sans-web-regular.woff") format("woff"); }

@font-face {
  font-family: "iA Writer Duospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/iAWriterDuoS-Regular.eot") format("embedded-opentype"), url("../../fonts/iAWriterDuoS-Regular.woff2") format("woff2"), url("../../fonts/iAWriterDuoS-Regular.woff") format("woff"); }

.grid {
  display: grid;
  grid-template-columns: 0 calc(var(--ken) * 5) repeat(9, 1fr) calc(var(--ken) * 3) 0;
  --grid-center-width: calc(100vw - (var(--ken) * 5) - (var(--ken) * 3));
  --grid-col-width: calc(var(--grid-center-width) / 9); }
  @media (min-width: 1440px) {
    .grid {
      grid-template-columns: 1fr calc(var(--ken) * 5) repeat(9, 149px) calc(var(--ken) * 3) 1fr;
      --grid-col-width: 149px; } }
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: 0 calc(var(--ken) * 3) repeat(9, 1fr) calc(var(--ken) * 1.5) 0; } }
  @media (max-width: 599px) {
    .grid {
      grid-template-columns: 0 calc(var(--ken) * 1.5) repeat(9, 1fr) calc(var(--ken) * 1.5) 0;
      --grid-center-width: calc(100vw - (var(--ken) * 1.5) - (var(--ken) * 1.5));
      --grid-col-width: calc(var(--grid-center-width) / 9); } }

.grid__aside-col {
  grid-column: 3/6; }
  @media (max-width: 900px) {
    .grid__aside-col {
      grid-column: 3 / -4; } }
  @media (max-width: 599px) {
    .grid__aside-col {
      grid-column: 3 / -3; } }

.grid__main-col {
  --main-col-padding-left: calc(var(--ken) * 4);
  --main-col-padding-right: 0px;
  grid-column: 6/-4;
  padding-left: var(--main-col-padding-left);
  padding-right: var(--main-col-padding-right); }
  @media (max-width: 900px) {
    .grid__main-col {
      --main-col-padding-left: 0px;
      --main-col-padding-right: calc(var(--ken) * 2);
      grid-column: 3 / -4; } }
  @media (max-width: 599px) {
    .grid__main-col {
      --main-col-padding-right: 0px;
      grid-column: 3 / -3; } }

.grid__wide-col {
  grid-column: 3 / -4; }
  @media (max-width: 599px) {
    .grid__wide-col {
      grid-column: 3 / -3; } }

.grid__full-col {
  grid-column: 3 / -3; }
  @media (max-width: 599px) {
    .grid__full-col {
      grid-column: 3 / -3; } }

.grid__left-col {
  --main-col-padding-left: 0px;
  --main-col-padding-right: calc(var(--ken) * 6);
  grid-column: 3 / 10;
  padding-right: var(--main-col-padding-right); }
  @media (max-width: 900px) {
    .grid__left-col {
      --main-col-padding-right: calc(var(--ken) * 2);
      grid-column: 3 / -4; } }
  @media (max-width: 599px) {
    .grid__left-col {
      --main-col-padding-right: 0;
      grid-column: 3 / -3; } }

body {
  --aside-text-color: var(--black);
  --aside-light-text-color: var(--gray-400);
  --aside-line-color: var(--gray-100);
  --aside-marker-color: var(--black);
  --aside-anchor-color: var(--gray-500);
  --mark-color: var(--yellow);
  --mark-text-color: var(--black); }

@media (min-width: 900px) {
  .theme--yellow {
    --aside-line-color: var(--white);
    --aside-light-text-color: var(--gray-400); }
    .theme--yellow .grid::before {
      content: "";
      height: 100%;
      width: 100%;
      align-self: stretch;
      grid-row: 1 / 20;
      grid-column: 1 / 6;
      background-color: var(--yellow); }
    .theme--yellow .aside strong,
    .theme--yellow .blockquote-aside strong,
    .theme--yellow .figure-aside strong {
      background-color: var(--white); }
    .theme--yellow .default__read-more-content {
      background-color: var(--black);
      color: var(--white); }
      .theme--yellow .default__read-more-content .aside-anchor {
        color: rgba(255, 255, 255, 0.4); }
    .theme--yellow .default__read-more::after {
      content: "";
      background-color: var(--black);
      height: 100%;
      width: 100%;
      align-self: stretch;
      grid-row: 2;
      grid-column: -4 / -1; }
    .theme--yellow .footer {
      color: var(--white); }
  .theme--black {
    --aside-text-color: var(--white);
    --aside-line-color: var(--white);
    --aside-light-text-color: rgba(255, 255, 255, 0.4); }
    .theme--black .header__logo {
      color: var(--white); }
    .theme--black .grid::before {
      content: "";
      height: 100%;
      width: 100%;
      align-self: stretch;
      grid-row: 1 / 20;
      grid-column: 1 / 6;
      background-color: var(--black); }
    .theme--black .default__read-more-content {
      background-color: var(--yellow); }
    .theme--black .default__read-more::after {
      content: "";
      background-color: var(--yellow);
      height: 100%;
      width: 100%;
      align-self: stretch;
      grid-row: 2;
      grid-column: -4 / -1; } }

.theme--red {
  --aside-text-color: var(--red);
  --aside-light-text-color: var(--black);
  --aside-line-color: var(--gray-100);
  --aside-marker-color: var(--black);
  --aside-anchor-color: var(--red);
  --mark-color: var(--red);
  --mark-text-color: var(--white); }
  .theme--red .header__logo {
    color: var(--red); }
  .theme--red .default__read-more-content {
    --aside-text-color: var(--red);
    --aside-anchor-color: var(--red); }
    .theme--red .default__read-more-content .notes-list__note {
      border-color: var(--gray-200); }
    .theme--red .default__read-more-content .notes-list__label,
    .theme--red .default__read-more-content .notes-list__note-date {
      color: var(--red); }
    @media (max-width: 599px) {
      .theme--red .default__read-more-content .notes-list__label {
        color: var(--gray-600); } }
  .theme--red .longread figcaption {
    color: var(--red); }
  .theme--red .longread ul li::before {
    color: var(--red); }
  .theme--red .longread ol li::before {
    color: var(--red); }
  .theme--red .longread h4,
  .theme--red .longread .headline--4 {
    color: var(--red); }
  .theme--red .longread--large a {
    text-decoration-color: var(--red); }
  .theme--red .repository__category-title {
    color: var(--red); }
    .theme--red .repository__category-title::before {
      background-color: var(--red); }
  @media (min-width: 900px) {
    .theme--red .grid::before {
      content: "";
      height: 100%;
      width: 100%;
      align-self: stretch;
      grid-row: 1 / 20;
      grid-column: -5 / -1;
      background-color: var(--red); }
    .theme--red .longread--large .aside--outside {
      --aside-text-color: var(--white); } }

.longread.annotated-text {
  --annotation-left-buffer: 0px;
  --annotation-right-buffer: 0px; }

@media (min-width: 900px) {
  .longread.annotated-text {
    --annotation-left-buffer: var(--main-col-padding-left);
    --annotation-right-buffer: var(--main-col-padding-right); }
    .longread.annotated-text > .longread-grid:not(.longread-grid--width-narrow),
    .longread.annotated-text > .longread-flexgrid:not(.longread-flexgrid--width-narrow) {
      margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
      margin-right: calc(var(--grid-col-width) * -1); }
    .longread.annotated-text .job-header {
      margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
      margin-right: calc(var(--grid-col-width) * -1); }
    .longread.annotated-text .figure-full {
      width: auto;
      margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
      margin-right: calc(var(--grid-col-width) * -1); }
      .longread.annotated-text .figure-full figcaption {
        margin-left: calc(var(--grid-col-width) * 3 + var(--annotation-left-buffer)); }
    .longread.annotated-text .figure-wide {
      width: auto;
      margin-right: calc(var(--grid-col-width) * -1); } }

@media (min-width: 1000px) {
  .longread.annotated-text ul {
    --annotation-left-buffer: calc(var(--main-col-padding-left) + (var(--list-padding-left) * 1)); }
  .longread.annotated-text ul ul {
    --annotation-left-buffer: calc(var(--main-col-padding-left) + (var(--list-padding-left) * 2)); }
  .longread.annotated-text ul ul ul {
    --annotation-left-buffer: calc(var(--main-col-padding-left) + (var(--list-padding-left) * 3)); }
  .longread.annotated-text:not(.longread--no-indent) p + .figure-aside + p,
  .longread.annotated-text:not(.longread--no-indent) p + .blockquote-aside + p {
    text-indent: 2em; }
  .longread.annotated-text .aside-anchor {
    display: inline; }
  .longread.annotated-text .aside {
    text-indent: 0;
    float: left;
    margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
    width: calc(var(--grid-col-width) * 3);
    transform: translateY(0.42em);
    clear: both;
    margin-bottom: 1.6em;
    display: flex;
    position: relative; }
    .longread.annotated-text .aside::before {
      content: "";
      border-top: 1px solid var(--aside-marker-color);
      width: calc(var(--ken) * 1);
      position: absolute;
      right: calc(var(--ken) * -1.5);
      top: 0;
      transform: translateY(4.5px); }
    .longread.annotated-text .aside::after {
      content: "";
      border-top: 1px solid var(--aside-line-color);
      margin-left: calc(var(--ken) * 0.5);
      flex-grow: 1;
      transform: translateY(4.5px); }
  .longread.annotated-text p:last-child .aside:last-child {
    margin-bottom: 0; }
  .longread.annotated-text .aside__content {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05px;
    --underline-offset: 1.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    transform: translateY(-0.4em);
    color: var(--aside-text-color);
    max-width: 66%;
    flex-grow: 0;
    transition: transform 0.3s; } }
  @media (min-width: 1000px) and (max-width: 599px) {
    .longread.annotated-text .aside__content {
      line-height: 19px; } }

@media (min-width: 1000px) {
    .longread.annotated-text .aside__content::after {
      content: "   (" counter(aside-counter) ")";
      color: var(--aside-light-text-color);
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 11.1px;
      line-height: 15px;
      --align-cap-height: -3.5px;
      --align-baseline: -3.5px;
      --underline-offset: 2px;
      vertical-align: 1px;
      line-height: inherit;
      transform: scale(0.79); }
    .longread.annotated-text .aside__content::before {
      display: none;
      content: "(" counter(aside-counter) ")   ";
      float: left;
      min-width: 36px;
      color: var(--aside-light-text-color);
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      line-height: inherit;
      transform: scale(0.79);
      transform-origin: center left; } }
    @media (min-width: 1000px) and (max-width: 599px) {
      .longread.annotated-text .aside__content::before {
        line-height: 19px; } }

@media (min-width: 1000px) {
  .longread.annotated-text .aside--minimal .aside__content::after {
    display: none; }
  .longread.annotated-text .aside--place .aside__content {
    display: flex; }
    .longread.annotated-text .aside--place .aside__content::before {
      display: inline;
      float: none; }
    .longread.annotated-text .aside--place .aside__content::after {
      display: none; }
  .longread.annotated-text .aside .cite {
    color: var(--aside-light-text-color); }
  .longread.annotated-text blockquote {
    position: relative; }
  .longread.annotated-text blockquote:not(.longquote):not(.blockquote-aside) cite {
    position: absolute;
    top: 0;
    margin-top: 0;
    margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
    width: calc(var(--grid-col-width) * 3);
    transform: translateY(3px);
    color: var(--aside-text-color);
    display: flex; }
    .longread.annotated-text blockquote:not(.longquote):not(.blockquote-aside) cite::before {
      content: "";
      border-top: 1px solid var(--aside-marker-color);
      transform: translateY(13px);
      width: calc(var(--ken) * 1);
      position: absolute;
      right: calc(var(--ken) * -1.5);
      top: 0; }
    .longread.annotated-text blockquote:not(.longquote):not(.blockquote-aside) cite::after {
      content: "";
      border-top: 1px solid var(--aside-line-color);
      margin-left: calc(var(--ken) * 0.5);
      flex-grow: 1;
      transform: translateY(13px);
      min-width: 29%; }
  .longread.annotated-text .blockquote-aside {
    display: inline;
    float: left;
    margin: 0;
    margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
    width: calc(var(--grid-col-width) * 3);
    position: relative;
    color: var(--aside-text-color);
    padding-right: calc(var(--ken) * 3); }
    .longread.annotated-text .blockquote-aside::before {
      content: "";
      border-top: 1px solid var(--aside-marker-color);
      transform: translateY(13px);
      width: calc(var(--ken) * 1);
      position: absolute;
      right: calc(var(--ken) * -1.5);
      top: 0; }
    .longread.annotated-text .blockquote-aside p:first-of-type::before,
    .longread.annotated-text .blockquote-aside p:last-of-type::after {
      color: var(--aside-light-text-color); }
    .longread.annotated-text .blockquote-aside cite {
      margin-right: calc(var(--ken) * 4);
      position: relative;
      color: var(--aside-light-text-color);
      margin-top: calc(var(--ken) * 0.75); }
  .longread.annotated-text .figure-aside {
    display: inline;
    margin: 0;
    left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
    width: calc(var(--grid-col-width) * 3);
    position: absolute; }
    .longread.annotated-text .figure-aside::before {
      content: "";
      border-top: 1px solid var(--aside-marker-color);
      transform: translateY(13px);
      width: calc(var(--ken) * 1);
      position: absolute;
      right: calc(var(--ken) * -1.5);
      top: 0; }
    .longread.annotated-text .figure-aside figcaption {
      color: var(--aside-text-color);
      margin-right: calc(var(--ken) * 4); }
      .longread.annotated-text .figure-aside figcaption::after {
        color: var(--aside-light-text-color); }
  .longread.annotated-text .job-header {
    display: grid;
    grid-template-columns: repeat(9, 1fr); } }
  @media (min-width: 1000px) and (min-width: 1440px) {
    .longread.annotated-text .job-header {
      grid-template-columns: repeat(9, 149px); } }
  @media (min-width: 1000px) and (max-width: 900px) {
    .longread.annotated-text .job-header {
      grid-template-columns: repeat(9, 1fr); } }
  @media (min-width: 1000px) and (max-width: 599px) {
    .longread.annotated-text .job-header {
      grid-template-columns: repeat(9, 1fr); } }

@media (min-width: 1000px) {
  .longread.annotated-text .job-header__company {
    grid-column: 1 / 3; }
  .longread.annotated-text .job-header__website {
    grid-column: 3 / 4; }
  .longread.annotated-text .job-header__title {
    grid-column: 4 / 9;
    padding-left: var(--main-col-padding-left);
    padding-right: var(--main-col-padding-right); }
  .longread.annotated-text .job-header__date {
    grid-column: 9; } }

@media (min-width: 900px) {
  .longread.annotated-text--large > .longread-grid:not(.longread-grid--width-narrow),
  .longread.annotated-text--large > .longread-flexgrid:not(.longread-flexgrid--width-narrow) {
    margin-left: 0;
    margin-right: calc(var(--grid-col-width) * -2 - var(--annotation-right-buffer)); } }

@media (min-width: 1000px) {
  .longread.annotated-text--large .aside {
    float: right;
    margin-right: calc(var(--grid-col-width) * 0 - var(--annotation-right-buffer));
    padding-left: calc(var(--ken) * 4);
    padding-right: calc(var(--ken) * 12);
    margin-left: 0;
    margin-top: calc(var(--ken) * 2.5);
    margin-bottom: calc(var(--ken) * 1.5) !important;
    width: calc(var(--grid-col-width) * 4);
    transform: none;
    hyphens: manual; }
    .longread.annotated-text--large .aside::before, .longread.annotated-text--large .aside::after {
      display: none; }
  .longread.annotated-text--large .aside--outside {
    margin-right: calc(var(--grid-col-width) * -2 - var(--annotation-right-buffer));
    width: calc(var(--grid-col-width) * 2);
    padding-left: calc(var(--ken) * 3);
    padding-right: 0;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: calc(var(--ken) * 1) !important;
    transform: translateY(0.32em); }
    .longread.annotated-text--large .aside--outside .aside__content .place__name {
      line-height: 1.27em;
      padding-top: 0.2em; }
  .longread.annotated-text--large figcaption .aside--outside {
    transform: translateY(0); }
  .longread.annotated-text--large .aside__content {
    max-width: 100%;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05px;
    --underline-offset: 1.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    transform: none; } }
  @media (min-width: 1000px) and (max-width: 599px) {
    .longread.annotated-text--large .aside__content {
      line-height: 19px; } }

@media (min-width: 1000px) {
    .longread.annotated-text--large .aside__content::before {
      display: inline-block; }
    .longread.annotated-text--large .aside__content::after {
      display: none; }
  .longread.annotated-text--large .aside--minimal .aside__content::before {
    display: none; }
  .longread.annotated-text--large .figure-full {
    width: auto;
    margin-left: 0;
    margin-right: calc(var(--grid-col-width) * -2 - var(--annotation-right-buffer)); }
    .longread.annotated-text--large .figure-full figcaption {
      margin-left: 0; }
  .longread.annotated-text--large .figure-wide {
    width: auto;
    margin-right: calc(var(--grid-col-width) * -1 - var(--annotation-right-buffer)); }
  .longread.annotated-text--large .figure-aside {
    margin: 0;
    margin-top: 10px;
    left: calc(var(--grid-col-width) * 7);
    width: calc(var(--grid-col-width) * 2);
    position: absolute;
    padding-left: calc(var(--ken) * 2); }
    .longread.annotated-text--large .figure-aside::before {
      display: none; }
    .longread.annotated-text--large .figure-aside figcaption {
      color: var(--white);
      margin-right: 0; } }

.arrow {
  height: 1em;
  width: auto; }

.footer {
  height: 0;
  position: relative;
  pointer-events: none;
  color: var(--gray-700); }

.footer__wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

.footer__content {
  grid-column: 3 / -3;
  justify-self: end;
  padding-bottom: calc(var(--ken) * 5); }

.footer__link {
  pointer-events: all;
  display: block;
  writing-mode: vertical-rl;
  transform: rotate(180deg) translateX(var(--align-baseline));
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  text-decoration: none;
  transition: none; }
  @media (max-width: 599px) {
    .footer__link {
      line-height: 19px; } }

@media (max-width: 599px) {
  .footer {
    height: auto; }
  .footer__wrapper {
    position: relative; }
  .footer__content {
    justify-self: start;
    padding-top: calc(var(--ken) * 3);
    padding-bottom: calc(var(--ken) * 1.5); }
  .footer__link {
    transform: unset;
    writing-mode: unset; } }

body {
  --frontmatter-margin-bottom: calc(var(--ken) * 14); }

.frontmatter__title {
  grid-row: 1;
  padding-bottom: var(--frontmatter-margin-bottom); }

.frontmatter__title-content {
  padding-top: calc(var(--ken) * 3);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 40px;
  line-height: 43px;
  letter-spacing: -1.6px;
  --align-cap-height: -7px;
  --align-baseline: -7px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 17em;
  hyphens: manual; }
  @media (max-width: 599px) {
    .frontmatter__title-content {
      font-size: 37px;
      line-height: 38px; } }
  .frontmatter__title-content::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.frontmatter__meta {
  grid-row: 1;
  align-self: end;
  color: var(--aside-text-color);
  padding-bottom: var(--frontmatter-margin-bottom); }

.frontmatter__meta-content {
  padding-top: calc(var(--ken) * 8);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  letter-spacing: 0.4px;
  word-spacing: 2px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1;
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .frontmatter__meta-content::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.frontmatter__meta-content--small {
  display: inline-block;
  margin-left: 1em;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px; }

@media (max-width: 900px) {
  body {
    --frontmatter-margin-bottom: calc(var(--ken) * 10); }
  .frontmatter {
    margin-bottom: var(--frontmatter-margin-bottom); }
  .frontmatter__title {
    padding-bottom: 0; }
  .frontmatter__title-content {
    padding-top: calc(var(--ken) * 12); }
  .frontmatter__meta {
    grid-row: 2;
    padding-bottom: 0; }
  .frontmatter__meta-content {
    padding-top: calc(var(--ken) * 3); } }

@media (max-width: 599px) {
  body {
    --frontmatter-margin-bottom: calc(var(--ken) * 7); }
  .frontmatter__title-content {
    padding-top: calc(var(--ken) * 6); }
  .frontmatter__meta-content {
    padding-top: calc(var(--ken) * 2); } }

body {
  --header-mobile-background-color: transparent; }

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  padding-top: calc(var(--ken) * 3);
  pointer-events: none; }

.header__content {
  pointer-events: none;
  grid-column: 3 / -3;
  display: flex;
  justify-content: space-between; }

.header__logo {
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .header__logo a {
    display: block;
    pointer-events: all;
    text-decoration: none;
    line-height: 14px; }
  .header__logo::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.header__title {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  letter-spacing: -0.1px;
  word-spacing: 1px; }
  @media (max-width: 599px) {
    .header__title {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.nav {
  list-style: none;
  margin: 0 auto;
  width: max-content;
  max-width: 100%;
  padding: 0;
  transition: color 0.1s;
  pointer-events: all;
  text-align: right;
  line-height: 26px;
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .nav::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  @media (max-width: 599px) {
    .nav {
      line-height: 24px; } }

.nav__item {
  display: block; }

.nav-link {
  transition: all 0.1s;
  --underline-thickness: 1.5px;
  text-decoration-color: transparent; }

.nav-link--active {
  text-decoration-color: currentColor; }

.header[data-theme="light"] {
  color: var(--white); }

@media (max-width: 599px) {
  .header[data-initial-theme="light"] {
    color: var(--white) !important; }
  .header {
    background-color: var(--header-mobile-background-color); } }

.header--non-sticky {
  position: absolute; }

@media (max-width: 599px) {
  .header {
    position: relative;
    padding-top: calc(var(--ken) * 2); } }

body {
  counter-reset: aside-counter; }

.longread {
  counter-reset: figure-counter figure-table-counter; }
  .longread p {
    max-width: 36em;
    hanging-punctuation: first; }
  .longread:not(.longread--no-indent) p + p {
    text-indent: 2.5em; }
  .longread.longread--no-indent p:not(:last-child) {
    margin-bottom: 0.7em; }
  .longread mark,
  .longread strong {
    font-weight: normal;
    background-color: var(--mark-color);
    color: var(--mark-text-color);
    padding: 0.05em 0.2em; }
  .longread em {
    font-style: normal; }
  .longread h2,
  .longread .headline--2 {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 40px;
    line-height: 43px;
    letter-spacing: -1.6px;
    --align-cap-height: -7px;
    --align-baseline: -7px;
    margin-top: calc(var(--ken) * 9);
    margin-bottom: calc(var(--ken) * 4);
    max-width: 28em; }
    @media (max-width: 599px) {
      .longread h2,
      .longread .headline--2 {
        font-size: 37px;
        line-height: 38px; } }
    .longread h2:first-child,
    .longread .headline--2:first-child {
      margin-top: 0; }
    .longread h2:last-child,
    .longread .headline--2:last-child {
      margin-bottom: 0; }
  .longread h3,
  .longread .headline--3 {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: -0.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    --underline-offset: 2px;
    --underline-thickness: 2px;
    letter-spacing: -0.1px;
    word-spacing: 2.2px;
    margin-top: calc(var(--ken) * 6);
    margin-bottom: calc(var(--ken) * 3);
    max-width: 28em; }
    @media (max-width: 599px) {
      .longread h3,
      .longread .headline--3 {
        font-size: 20px;
        line-height: 26px;
        --underline-thickness: 1.5px; } }
    .longread h3:first-child,
    .longread .headline--3:first-child {
      margin-top: 0; }
    .longread h3:last-child,
    .longread .headline--3:last-child {
      margin-bottom: 0; }
    @media (max-width: 599px) {
      .longread h3,
      .longread .headline--3 {
        margin-bottom: calc(var(--ken) * 2); } }
  .longread h4,
  .longread .headline--4 {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-mono);
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.5px;
    word-spacing: -2px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    --underline-offset: 1.5px;
    word-spacing: -1px;
    line-height: 22px;
    color: var(--gray-900);
    margin-top: calc(var(--ken) * 4);
    margin-bottom: calc(var(--ken) * 1);
    max-width: 40em; }
    @media (max-width: 599px) {
      .longread h4,
      .longread .headline--4 {
        line-height: 20px; } }
    .longread h4:first-child,
    .longread .headline--4:first-child {
      margin-top: 0; }
    .longread h4:last-child,
    .longread .headline--4:last-child {
      margin-bottom: 0; }
    @media (max-width: 599px) {
      .longread h4,
      .longread .headline--4 {
        margin-bottom: calc(var(--ken) * 0.75); } }
  .longread .headline-link {
    user-select: none;
    text-decoration: none;
    opacity: 0;
    transition: opacity 0.5s;
    break-before: avoid;
    white-space: nowrap; }
  .longread h2:hover .headline-link,
  .longread .headline--2:hover .headline-link,
  .longread h3:hover .headline-link,
  .longread .headline--3:hover .headline-link,
  .longread h4:hover .headline-link,
  .longread .headline--4:hover .headline-link {
    opacity: 0.3;
    transition: opacity 0.5s 1s; }
  .longread .cite {
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    letter-spacing: -0.1px;
    word-spacing: 1px;
    font-size: calc(17px * 0.65);
    color: var(--aside-anchor-color);
    font-variant-numeric: tabular-nums;
    font-feature-settings: "zero" 1;
    margin-left: 3px;
    margin-right: 3px;
    hanging-punctuation: none;
    text-indent: 0;
    line-height: inherit;
    vertical-align: bottom;
    white-space: nowrap; }
    @media (max-width: 599px) {
      .longread .cite {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
    @media (max-width: 599px) {
      .longread .cite {
        font-size: calc(17px * 0.65); } }
  .longread .figure-reference {
    color: var(--gray-500);
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    letter-spacing: -0.1px;
    word-spacing: 1px;
    font-variant-numeric: tabular-nums;
    letter-spacing: 0.03em;
    font-feature-settings: "zero" 1;
    transform: scale(0.7);
    margin-left: -8px;
    margin-right: -8px;
    white-space: nowrap;
    hanging-punctuation: none;
    text-indent: 0; }
    @media (max-width: 599px) {
      .longread .figure-reference {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
    .longread .figure-reference--previous::before {
      content: "(fig. " counter(figure-counter) ")"; }
  .longread figure {
    margin: 0;
    width: 100%;
    margin-top: calc(var(--ken) * 4.5);
    margin-bottom: calc(var(--ken) * 4.5); }
    .longread figure:first-child {
      margin-top: 0; }
    .longread figure:last-child {
      margin-bottom: 0; }
    @media (max-width: 599px) {
      .longread figure {
        margin-top: calc(var(--ken) * 3);
        margin-bottom: calc(var(--ken) * 3); } }
  .longread figure:not(.figure-table) {
    counter-increment: figure-counter; }
  .longread figure.figure-table {
    counter-increment: figure-table-counter; }
  .longread figure img,
  .longread figure video {
    max-width: 100%;
    display: block; }
  .longread figure figcaption {
    margin-top: calc(var(--ken) * 1);
    max-width: 36em; }
  .longread figure.figure-table figcaption {
    margin-top: calc(var(--ken) * 2); }
  .longread figure figcaption::after {
    content: "(fig. " counter(figure-counter) ")";
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    letter-spacing: -0.1px;
    word-spacing: 1px;
    font-variant-numeric: tabular-nums;
    letter-spacing: 0.03em;
    font-feature-settings: "zero" 1;
    transform: scale(0.7);
    transform-origin: center left;
    color: var(--gray-500);
    white-space: nowrap; }
    @media (max-width: 599px) {
      .longread figure figcaption::after {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  .longread figure.figure-table figcaption::after {
    content: "(table " counter(figure-table-counter) ")"; }
  .longread .longread-grid {
    margin-top: calc(var(--ken) * 5);
    margin-bottom: calc( var(--ken) * 5 - (var(--longread-grid-column-gap) * 1));
    --longread-grid-columns: 4;
    --longread-grid-column-gap: calc(var(--ken) * 2);
    column-gap: var(--longread-grid-column-gap);
    columns: var(--longread-grid-columns); }
    @media (max-width: 900px) {
      .longread .longread-grid {
        --longread-grid-columns: 2 !important; } }
    @media (max-width: 599px) {
      .longread .longread-grid {
        --longread-grid-column-gap: calc(var(--ken) * 1);
        margin-top: calc(var(--ken) * 3);
        padding-bottom: calc(var(--ken) * 3); } }
    .longread .longread-grid > * {
      break-inside: avoid;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: calc(var(--longread-grid-column-gap) * 1); }
    .longread .longread-grid figure figcaption {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      margin-top: calc(var(--ken) * 0.75); }
      @media (max-width: 599px) {
        .longread .longread-grid figure figcaption {
          line-height: 19px; } }
    .longread .longread-grid figure figcaption::after {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: uppercase;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      letter-spacing: 0.3px;
      word-spacing: 1px;
      transform: scale(0.8); }
      @media (max-width: 599px) {
        .longread .longread-grid figure figcaption::after {
          line-height: 19px; } }
  .longread .longread-flexgrid {
    margin-top: calc(var(--ken) * 5);
    margin-bottom: calc(var(--ken) * 5);
    display: flex;
    --longread-grid-column-gap: calc(var(--ken) * 1.5);
    column-gap: var(--longread-grid-column-gap); }
    .longread .longread-flexgrid--width-narrow {
      --longread-grid-column-gap: calc(var(--ken) * 1); }
    @media (max-width: 599px) {
      .longread .longread-flexgrid {
        --longread-grid-column-gap: calc(var(--ken) * 1);
        margin-top: calc(var(--ken) * 3);
        margin-bottom: calc(var(--ken) * 3); } }
    .longread .longread-flexgrid > * {
      break-inside: avoid;
      margin-top: 0; }
    .longread .longread-flexgrid figure {
      margin-bottom: 0;
      flex-grow: calc(var(--aspect-ratio));
      flex-basis: 0; }
    .longread .longread-flexgrid figure figcaption {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      margin-top: calc(var(--ken) * 0.75); }
      @media (max-width: 599px) {
        .longread .longread-flexgrid figure figcaption {
          line-height: 19px; } }
    .longread .longread-flexgrid figure figcaption::after {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: uppercase;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      letter-spacing: 0.3px;
      word-spacing: 1px;
      transform: scale(0.8); }
      @media (max-width: 599px) {
        .longread .longread-flexgrid figure figcaption::after {
          line-height: 19px; } }
  .longread .headline--3 + figure:not(.figure-aside),
  .longread .headline--3 + .longread-grid,
  .longread .headline--3 + table {
    margin-top: calc(var(--ken) * 3); }
  .longread .headline--4 + figure:not(.figure-aside),
  .longread .headline--4 + .longread-grid,
  .longread .headline--4 + table {
    margin-top: calc(var(--ken) * 2); }
  .longread table {
    border-collapse: collapse;
    table-layout: fixed;
    empty-cells: show;
    width: 100%;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05px;
    --underline-offset: 1.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px; }
    @media (max-width: 599px) {
      .longread table {
        line-height: 19px; } }
    .longread table p + p {
      text-indent: 0 !important; }
    .longread table p:not(:last-child) {
      margin-bottom: 0.3em !important; }
    .longread table thead {
      position: sticky;
      top: 0; }
    .longread table th {
      border-bottom: 1px solid var(--black);
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: uppercase;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 11.1px;
      line-height: 15px;
      --align-cap-height: -3.5px;
      --align-baseline: -3.5px;
      --underline-offset: 2px;
      letter-spacing: 0.4px;
      word-spacing: 2px; }
    .longread table tr:not(:last-child) {
      border-bottom: 1px solid var(--gray-100); }
    .longread table td,
    .longread table th {
      text-align: left;
      vertical-align: top;
      padding: calc(var(--ken) * 0.8) calc(var(--ken) * 1); }
      .longread table td:first-child,
      .longread table th:first-child {
        padding-left: 0; }
      .longread table td:last-child,
      .longread table th:last-child {
        padding-right: 0; }
  .longread hr {
    margin-top: calc(var(--ken) * 6);
    margin-bottom: calc(var(--ken) * 6);
    border: none;
    border-top: 1px solid var(--gray-200);
    clear: both; }
    .longread hr:first-child {
      margin-top: calc(var(--ken) * 1); }
    .longread hr:last-child {
      margin-bottom: 0; }
  .longread .hr-invisible {
    border-top-color: transparent; }
  .longread ul,
  .longread ol {
    margin-top: calc(var(--ken) * 1);
    margin-bottom: calc(var(--ken) * 1); }
    .longread ul:first-child,
    .longread ol:first-child {
      margin-top: 0; }
    .longread ul:last-child,
    .longread ol:last-child {
      margin-bottom: 0; }
  .longread li {
    max-width: 35em;
    padding-top: 0.15em;
    padding-bottom: 0.15em; }
  .longread ul {
    list-style: none;
    --list-padding-left: 15px;
    padding-left: var(--list-padding-left); }
  .longread ul ul {
    margin-top: 0.15em; }
  .longread ul li::before {
    content: "⁕";
    display: inline-block;
    vertical-align: 0.08em;
    color: var(--gray-700);
    transform: scale(0.8);
    transform-origin: center left;
    margin-left: calc(var(--list-padding-left) * -1);
    width: var(--list-padding-left); }
  .longread ol {
    list-style: none;
    --list-padding-left: 18px;
    padding-left: var(--list-padding-left); }
  .longread > ol {
    counter-reset: ol; }
  .longread > ol li {
    counter-increment: ol; }
  .longread > ol ol {
    margin-top: 0.15em;
    counter-reset: olol;
    --list-padding-left: 28px; }
  .longread > ol ol li {
    counter-increment: olol; }
  .longread > ol ol ol {
    counter-reset: ololol;
    --list-padding-left: 44px; }
  .longread > ol ol ol li {
    counter-increment: ololol; }
  .longread ol li::before {
    content: counter(ol);
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    font-variant-numeric: tabular-nums;
    font-feature-settings: "zero" 1;
    transform: scale(0.7);
    transform-origin: center left;
    color: var(--gray-400);
    margin-left: calc(var(--list-padding-left) * -1);
    width: calc(var(--list-padding-left));
    text-align: right;
    padding-right: 8px; }
    @media (max-width: 599px) {
      .longread ol li::before {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  .longread ol ol li::before {
    content: counter(ol) "." counter(olol); }
  .longread ol ol ol li::before {
    content: counter(ol) "." counter(olol) "." counter(ololol); }
  .longread blockquote {
    margin: 0;
    margin-top: calc(var(--ken) * 4);
    margin-bottom: calc(var(--ken) * 4); }
    .longread blockquote:first-child {
      margin-top: 0; }
    .longread blockquote:last-child {
      margin-bottom: 0; }
    .longread blockquote p {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-mono);
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.3px;
      word-spacing: -5px; }
      @media (max-width: 599px) {
        .longread blockquote p {
          font-size: 18px;
          line-height: 27px; } }
    .longread blockquote p + p {
      text-indent: 0 !important;
      margin-top: 0.5em; }
    .longread blockquote p:first-of-type::before {
      content: "“";
      color: var(--gray-500);
      display: inline-block;
      margin-left: -0.7em;
      width: 0.7em;
      padding-right: 0.07em;
      text-align: right; }
    .longread blockquote p:last-of-type::after {
      break-before: avoid;
      content: "”";
      color: var(--gray-500); }
  .longread cite {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    display: block;
    margin-top: calc(var(--ken) * 1); }
    @media (max-width: 599px) {
      .longread cite {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  .longread .headline--3 + blockquote:not(.blockquote-aside) {
    margin-top: calc(var(--ken) * 4); }
  .longread .headline--4 + blockquote:not(.blockquote-aside) {
    margin-top: calc(var(--ken) * 3); }
  .longread .longquote {
    border-top: none;
    padding-top: 0;
    margin-top: calc(var(--ken) * 3);
    margin-bottom: calc(var(--ken) * 4); }
    .longread .longquote:has(em) {
      color: var(--gray-400); }
    .longread .longquote em {
      color: var(--black); }
    .longread .longquote p {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-mono);
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.5px;
      word-spacing: -2px;
      --align-cap-height: -5px;
      --align-baseline: -5px;
      --underline-offset: 1.5px;
      max-width: 48em; }
      @media (max-width: 599px) {
        .longread .longquote p {
          line-height: 20px; } }
    .longread .longquote p + p {
      margin-top: 0.8em; }
    .longread .longquote cite {
      margin-top: calc(var(--ken) * 1.25);
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: uppercase;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 11.1px;
      line-height: 15px;
      --align-cap-height: -3.5px;
      --align-baseline: -3.5px;
      --underline-offset: 2px;
      letter-spacing: 0.4px;
      word-spacing: 2px; }
    .longread .longquote .aside__content {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.05px;
      --underline-offset: 1.5px;
      --align-cap-height: -5px;
      --align-baseline: -5px; }
      @media (max-width: 599px) {
        .longread .longquote .aside__content {
          line-height: 19px; } }
  .longread .headline--4 + blockquote.longquote {
    margin-top: calc(var(--ken) * 2);
    margin-bottom: calc(var(--ken) * 4); }
  .longread .job-header {
    margin-top: calc(var(--ken) * 10);
    padding-top: calc(var(--ken) * 1);
    border-top: 1px solid currentColor;
    margin-bottom: calc(var(--ken) * 2);
    display: grid;
    grid-template-columns: 1fr auto; }
  .longread .job-header__website,
  .longread .job-header__date {
    text-align: right; }
  .longread .job-header__website a {
    text-decoration: none; }
  .longread .headline--3 + .job-header {
    margin-top: calc(var(--ken) * 4); }
  .longread .aside-anchor {
    display: none;
    white-space: nowrap; }
  .longread .aside-anchor__content {
    display: inline-block;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    color: var(--aside-anchor-color);
    font-variant-numeric: tabular-nums;
    font-feature-settings: "zero" 1;
    margin-left: -3.5px;
    margin-right: -3.5px;
    transform: scale(0.65);
    hanging-punctuation: none;
    text-indent: 0;
    counter-increment: aside-counter;
    line-height: inherit;
    vertical-align: bottom;
    white-space: nowrap; }
    @media (max-width: 599px) {
      .longread .aside-anchor__content {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
    .longread .aside-anchor__content::after {
      content: "(" counter(aside-counter) ")"; }
  .longread .aside {
    color: var(--gray-600);
    line-height: inherit !important;
    vertical-align: top; }
    .longread .aside::before {
      content: "("; }
    .longread .aside::after {
      content: ")"; }
  .longread .aside--minimal {
    display: none; }
  .longread .aside--no-inline {
    display: none; }
  .longread .table-of-contents a {
    text-decoration-color: var(--gray-200); }

.longread--small {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px; }
  @media (max-width: 599px) {
    .longread--small {
      line-height: 19px; } }
  .longread--small h3,
  .longread--small .headline--3 {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: -0.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    --underline-offset: 2px;
    --underline-thickness: 2px;
    margin-top: calc(var(--ken) * 3);
    margin-bottom: calc(var(--ken) * 2.5); }
    @media (max-width: 599px) {
      .longread--small h3,
      .longread--small .headline--3 {
        font-size: 20px;
        line-height: 26px;
        --underline-thickness: 1.5px; } }
  .longread--small h4,
  .longread--small .headline--4 {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-mono);
    font-size: 11.5px;
    line-height: 19px;
    letter-spacing: 0.2px;
    word-spacing: -2px;
    --underline-offset: 1.5px;
    --underline-thickness: 0.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    letter-spacing: 0.4px;
    margin-top: calc(var(--ken) * 2.5);
    margin-bottom: calc(var(--ken) * 0.75); }
  .longread--small figure {
    margin-top: calc(var(--ken) * 2.5);
    margin-bottom: calc(var(--ken) * 2.5); }
  .longread--small figure figcaption::after {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05px;
    --underline-offset: 1.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    letter-spacing: 0.3px;
    word-spacing: 1px;
    letter-spacing: 0.05em;
    transform: scale(0.8); }
    @media (max-width: 599px) {
      .longread--small figure figcaption::after {
        line-height: 19px; } }
  .longread--small blockquote {
    margin-top: calc(var(--ken) * 3);
    margin-bottom: calc(var(--ken) * 3); }
    .longread--small blockquote p {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-mono);
      font-size: 16px;
      line-height: 23.5px;
      letter-spacing: -0.05px;
      word-spacing: -3px; }
      .longread--small blockquote p strong {
        padding-top: 0;
        padding-bottom: 0; }
  .longread--small .headline--3 + blockquote:not(.blockquote-aside) {
    margin-top: calc(var(--ken) * 3); }
  .longread--small cite {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.05px;
    --underline-offset: 1.5px;
    --align-cap-height: -5px;
    --align-baseline: -5px;
    margin-top: calc(var(--ken) * 0.75); }
    @media (max-width: 599px) {
      .longread--small cite {
        line-height: 19px; } }
  .longread--small .longquote {
    margin-top: calc(var(--ken) * 2.5);
    margin-bottom: calc(var(--ken) * 3); }
  .longread--small .headline--4 + blockquote.longquote {
    margin-top: calc(var(--ken) * 1.5); }

.longread--large {
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -2px;
  word-spacing: 0.9px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 3px;
  --underline-thickness: 2.5px; }
  @media (max-width: 900px) {
    .longread--large {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -1.6px;
      word-spacing: 0.9px; } }
  @media (max-width: 599px) {
    .longread--large {
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0px;
      word-spacing: 0;
      --underline-offset: auto;
      --underline-thickness: auto;
      --align-cap-height: 0;
      --align-baseline: 0;
      text-transform: none;
      font-family: var(--font-family-sans);
      font-feature-settings: "zero" 1;
      font-variant-numeric: tabular-nums;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: -0.5px;
      --align-cap-height: -6px;
      --align-baseline: -6px;
      --underline-offset: 2px;
      --underline-thickness: 2px; } }
  @media (max-width: 599px) and (max-width: 599px) {
    .longread--large {
      font-size: 20px;
      line-height: 26px;
      --underline-thickness: 1.5px; } }
  .longread--large mark,
  .longread--large strong {
    padding: 0.02em 0.2em; }
  .longread--large p {
    max-width: unset;
    hyphens: auto; }
  .longread--large:not(.longread--no-indent) p + p {
    text-indent: calc(var(--grid-col-width) * 2); }
  .longread--large h4,
  .longread--large .headline--4 {
    max-width: unset;
    margin-top: calc(var(--ken) * 7);
    margin-bottom: calc(var(--ken) * 1.5); }
    .longread--large h4:first-child,
    .longread--large .headline--4:first-child {
      margin-top: 0; }
    .longread--large h4:last-child,
    .longread--large .headline--4:last-child {
      margin-bottom: 0; }
    @media (max-width: 599px) {
      .longread--large h4,
      .longread--large .headline--4 {
        margin-top: calc(var(--ken) * 4);
        margin-bottom: calc(var(--ken) * 0.75); } }
  .longread--large figure figcaption {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    max-width: unset; }
    @media (max-width: 599px) {
      .longread--large figure figcaption {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  .longread--large ul {
    --list-padding-left: 28px; }
  .longread--large ol {
    --list-padding-left: 34px; }
  .longread--large hr {
    margin-top: calc(var(--ken) * 2);
    margin-bottom: calc(var(--ken) * 2); }
    .longread--large hr:first-child {
      margin-top: calc(var(--ken) * 1); }
    .longread--large hr:last-child {
      margin-bottom: 0; }

.notes-list {
  list-style: none; }

.notes-list__note {
  padding: calc(var(--ken) * 1.5) 0;
  border-top: 1px solid currentColor;
  transition: border-color 0.3s; }
  @media (max-width: 599px) {
    .notes-list__note {
      border: none; } }

.notes-list__note-link {
  transform: translateY(calc(-1 * var(--align-baseline)));
  text-decoration: none;
  display: block;
  transition: color 0.3s; }
  .notes-list__note-link::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.notes-list__note-grid {
  display: grid;
  grid-template-columns: calc(var(--ken) * 11.5) 1fr;
  column-gap: calc(var(--ken) * 2.5); }
  .notes-list__note-grid .tags {
    display: inline;
    vertical-align: 0.15em; }
  .notes-list__note-grid .tag {
    transform: scale(0.9);
    transform-origin: center left; }
  @media (max-width: 599px) {
    .notes-list__note-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      row-gap: calc(var(--ken) * 0.25); } }

.notes-list__note:first-child {
  padding-top: 0;
  border-top: none; }

.notes-list__note:last-child {
  padding-bottom: 0; }

.notes-list__note-link--highlight {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  letter-spacing: -0.1px;
  word-spacing: 1px; }
  @media (max-width: 599px) {
    .notes-list__note-link--highlight {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.notes-list__note-date {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  letter-spacing: 0.4px;
  word-spacing: 2px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1;
  padding-top: 5.5px; }

.notes-list__note--fade-out .notes-list__note-link {
  color: var(--gray-300); }

.notes-list__note--fade-out + .notes-list__note--fade-out {
  border-top-color: var(--gray-200); }

.notes-list__label {
  margin-top: calc(var(--ken) * 4);
  margin-bottom: calc(var(--ken) * 1.5);
  margin-left: calc(var(--ken) * 14);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  letter-spacing: 1px; }
  @media (max-width: 599px) {
    .notes-list__label {
      margin-left: 0;
      margin-top: calc(var(--ken) * 3);
      margin-bottom: calc(var(--ken) * 1); } }

.tags {
  list-style: none;
  margin: 0;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  letter-spacing: 0.4px;
  word-spacing: 2px;
  word-spacing: 7px;
  line-height: 20px; }

.tag {
  display: inline-block;
  word-spacing: -0.2px; }

.tags:not(.tags--theme-minimal) .tag--highlight {
  padding: 0 0.7em;
  margin-right: 0.1em;
  position: relative; }
  .tags:not(.tags--theme-minimal) .tag--highlight::after {
    content: "";
    position: absolute;
    top: -0.14em;
    bottom: -0.12em;
    right: 0;
    left: 0;
    pointer-events: none;
    border: 1px solid currentColor;
    border-radius: 1em; }
  @media (max-width: 599px) {
    .tags:not(.tags--theme-minimal) .tag--highlight::after {
      top: -0.1em;
      bottom: -0.15em; } }

.tag__count {
  opacity: 0.5; }

.tags--theme-minimal .tag {
  color: var(--black); }

.tags--interactive .tag {
  transition: color 0.3s;
  cursor: pointer; }

.tags--interactive:not(.tags--filtering):hover .tag:not(:hover) {
  color: var(--gray-400); }

.tags--theme-minimal.tags--filtering .tag {
  color: var(--gray-400); }

.tags--theme-minimal.tags--filtering .tag--active {
  color: var(--black); }

.youtube-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden; }
  .youtube-embed iframe,
  .youtube-embed object,
  .youtube-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100; }
  .youtube-embed lite-youtube {
    max-width: unset !important; }
    .youtube-embed lite-youtube::before {
      display: none; }

.place {
  display: inline-block;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px; }
  @media (max-width: 599px) {
    .place {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.place__name {
  display: block; }
  .place__name a {
    text-decoration: none; }

.place__location a {
  text-decoration: none; }

@media (max-width: 599px) {
  .place__location {
    display: none; } }

.place__note {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  margin-top: 7px;
  display: block;
  hyphens: auto;
  color: var(--black); }
  @media (max-width: 599px) {
    .place__note {
      line-height: 19px; } }
  @media (max-width: 599px) {
    .place__note {
      margin-top: 4px;
      color: var(--gray-700); } }

.aside__content .place {
  transform: translateY(-0.15em); }

.aside__content .place .place__location {
  color: var(--aside-light-text-color); }

.aside__content .place .place__note {
  color: var(--aside-light-text-color); }

.location {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 11.5px;
  line-height: 19px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  --underline-offset: 1.5px;
  --underline-thickness: 0.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.4px;
  display: block;
  margin-top: 0px;
  color: var(--gray-400);
  word-spacing: -0.4em; }
  .location a {
    text-decoration: none; }
  .location span {
    margin-left: 0.9em; }

.repository {
  columns: 2;
  counter-reset: repository;
  column-gap: 0;
  margin-bottom: calc(var(--ken) * -4); }
  @media (min-width: 900px) {
    .repository {
      columns: 3; } }

.repository__category {
  padding-bottom: calc(var(--ken) * 4);
  padding-right: calc(var(--ken) * 4);
  break-inside: avoid; }

.repository__category-title {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  padding-bottom: calc(var(--ken) * 1.5);
  color: var(--black);
  counter-increment: repository; }
  @media (max-width: 599px) {
    .repository__category-title {
      line-height: 19px; } }
  .repository__category-title::before {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 11.1px;
    line-height: 15px;
    --align-cap-height: -3.5px;
    --align-baseline: -3.5px;
    --underline-offset: 2px;
    letter-spacing: 0.4px;
    word-spacing: 2px;
    content: counter(repository);
    background-color: var(--black);
    color: var(--white);
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 50%;
    vertical-align: 1px;
    text-align: center;
    margin-right: 0.5em; }

.repository__category-places {
  display: grid;
  row-gap: calc(var(--ken) * 2); }

@media (max-width: 599px) {
  .repository__category {
    padding-right: calc(var(--ken) * 2); } }

.bookshelf {
  --header-mobile-background-color: var(--black); }

.bookshelf__top {
  background-color: var(--black);
  color: var(--white);
  --aside-text-color: var(--white);
  --aside-line-color: rgba(255, 255, 255, 0.3);
  --aside-marker-color: var(--white);
  --aside-light-text-color: rgba(255, 255, 255, 0.4);
  --aside-anchor-color: rgba(255, 255, 255, 0.4); }
  @media (max-width: 1200px) {
    .bookshelf__top .aside {
      color: rgba(255, 255, 255, 0.5); } }

.bookshelf__intro {
  margin-top: calc((var(--ken) * 4) - var(--frontmatter-margin-bottom));
  padding-bottom: calc(var(--ken) * 5);
  --annotation-left-buffer: var(--main-col-padding-left); }
  @media (max-width: 599px) {
    .bookshelf__intro {
      padding-bottom: calc(var(--ken) * 5); } }

.bookshelf__intro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 32em; }
  @media (max-width: 599px) {
    .bookshelf__intro-text {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }
  .bookshelf__intro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.bookshelf__intro-links {
  margin-top: calc(var(--ken) * 1.2);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  color: rgba(255, 255, 255, 0.3);
  word-spacing: -0px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1; }
  @media (max-width: 599px) {
    .bookshelf__intro-links {
      line-height: 19px; } }
  .bookshelf__intro-links a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none; }
    .bookshelf__intro-links a:hover {
      text-decoration: underline; }
  @media (max-width: 599px) {
    .bookshelf__intro-links {
      margin-top: calc(var(--ken) * 1.5);
      line-height: 22px; } }

.bookshelf-vis {
  display: grid;
  grid-template-rows: auto auto;
  grid-column: 1 / -1; }
  .bookshelf-vis::after {
    width: 100%;
    height: 13px;
    content: "";
    background-color: var(--white);
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black); }
  @media (max-width: 599px) {
    .bookshelf-vis {
      display: none; } }

.bookshelf-vis__books {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  padding: 0 calc(var(--ken) * 5);
  width: 100%;
  column-gap: 1px;
  align-items: end;
  position: relative; }
  @media (min-width: 1440px) {
    .bookshelf-vis__books {
      padding: 0 calc((100vw - 1320px) / 2); } }
  @media (max-width: 599px) {
    .bookshelf-vis__books {
      padding: 0 calc(var(--ken) * 1.5); } }

.bookshelf-vis__book {
  background-color: var(--white);
  color: var(--black);
  display: grid;
  grid-auto-flow: column;
  align-items: end;
  justify-content: center;
  padding: calc(var(--ken) * 1) calc(var(--ken) * 0.8);
  --padding-top: calc(var(--ken) * (4 + var(--random-multiplier)));
  padding-top: var(--padding-top);
  min-height: calc(13em + var(--padding-top));
  min-width: calc(var(--pages) * 0.25px);
  width: max-content;
  text-decoration: none;
  position: relative; }

.bookshelf-vis__book-author {
  writing-mode: vertical-rl;
  text-align: left;
  transform: rotate(180deg);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  color: var(--gray-600);
  max-height: 28em;
  z-index: 1;
  margin-left: -0.2em;
  margin-right: 0.15em; }

.bookshelf-vis__book-title {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  line-height: 1.22em;
  writing-mode: vertical-rl;
  text-align: left;
  transform: rotate(180deg);
  max-height: 22em;
  z-index: 1; }

.bookshelf-vis__book-meta {
  writing-mode: vertical-rl;
  text-align: left;
  display: none;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  transform: rotate(180deg);
  color: var(--gray-500);
  z-index: 1; }

.bookshelf-vis__date-marker {
  writing-mode: vertical-rl;
  text-align: left;
  transform: rotate(180deg);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: -1.2px;
  word-spacing: 3px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 2px;
  --underline-thickness: 2.5px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1;
  padding-top: calc(var(--ken) * 1);
  padding-left: calc(var(--ken) * 0.5);
  padding-right: calc(var(--ken) * 2); }
  .bookshelf-vis__date-marker:first-child {
    padding-right: 0; }
  .bookshelf-vis__date-marker .arrow {
    transform: rotate(180deg); }
  .bookshelf-vis__date-marker--current {
    padding-right: calc(var(--ken) * 0.5);
    padding-left: 0; }
    .bookshelf-vis__date-marker--current .arrow {
      transform: rotate(0deg); }

.bookshelf__main {
  margin-top: calc(var(--ken) * 6); }
  @media (max-width: 900px) {
    .bookshelf__main {
      margin-top: calc(var(--ken) * 4); } }
  @media (max-width: 599px) {
    .bookshelf__main {
      margin-top: calc(var(--ken) * 2); } }

.bookshelf__content {
  padding-bottom: calc(var(--ken) * 5); }
  @media (max-width: 900px) {
    .bookshelf__content {
      margin-top: calc(var(--ken) * 3); } }

.bookshelf__text {
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .bookshelf__text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.booklist__year {
  margin-top: calc(var(--ken) * 9); }
  .booklist__year:first-child {
    margin-top: 0; }

.booklist__covers-wrapper {
  display: flex;
  justify-content: end;
  margin-left: calc(var(--grid-col-width) * -3 - var(--annotation-left-buffer));
  width: calc(var(--grid-col-width) * 9);
  margin-top: calc(var(--ken) * 6);
  margin-bottom: calc(var(--ken) * 3); }
  @media (max-width: 900px) {
    .booklist__covers-wrapper {
      margin-left: 0;
      width: 100%; } }
  @media (max-width: 599px) {
    .booklist__covers-wrapper {
      margin-top: calc(var(--ken) * 4);
      margin-bottom: calc(var(--ken) * 5); } }

.booklist__covers {
  display: flex;
  align-items: center;
  row-gap: calc(var(--ken) * 4);
  column-gap: calc(var(--ken) * 4);
  flex-wrap: wrap;
  width: auto;
  min-width: calc(var(--grid-col-width) * 6 - var(--annotation-left-buffer)); }
  @media (max-width: 900px) {
    .booklist__covers {
      row-gap: calc(var(--ken) * 3);
      column-gap: calc(var(--ken) * 3);
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); } }
  @media (max-width: 599px) {
    .booklist__covers {
      row-gap: calc(var(--ken) * 2);
      column-gap: calc(var(--ken) * 2);
      grid-template-columns: repeat(auto-fill, minmax(70px, 1fr)); } }

.booklist__covers-cover {
  width: 106.1px;
  display: block;
  position: relative;
  box-shadow: 0 10px 10px -9px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  overflow: hidden;
  transition: all 0.3s; }
  @media (max-width: 900px) {
    .booklist__covers-cover {
      width: auto; } }
  .booklist__covers-cover:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    box-shadow: inset 4px 1px 3px #ffffff60, inset 0 -1px 2px #00000080;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%); }
  .booklist__covers-cover:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    width: 20px;
    margin-left: 3px;
    border-left: 2px solid #00000024;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%); }
  .booklist__covers-cover picture {
    display: block; }
  .booklist__covers-cover img {
    display: block;
    max-width: 100%;
    width: 100%; }
  .booklist__covers-cover--fade-out {
    opacity: 0.1;
    filter: grayscale(1); }

.booklist__year-tags {
  color: var(--gray-600);
  margin-bottom: calc(var(--ken) * 3); }
  @media (max-width: 900px) {
    .booklist__year-tags {
      display: none; } }

.booklist__book {
  margin-top: calc(var(--ken) * 6);
  margin-bottom: calc(var(--ken) * 6); }
  .booklist__book h3 {
    position: relative; }
    @media (max-width: 599px) {
      .booklist__book h3 {
        margin-bottom: calc(var(--ken) * 3); } }
  .booklist__book:last-child {
    margin-bottom: 0; }

.booklist__book-author {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-mono);
  font-size: 16px;
  line-height: 23.5px;
  letter-spacing: -0.05px;
  word-spacing: -3px;
  margin-bottom: 0.3em; }
  .booklist__book-author strong {
    padding-top: 0;
    padding-bottom: 0; }

.booklist__book-meta {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  margin-top: 0.4em; }
  @media (max-width: 599px) {
    .booklist__book-meta {
      line-height: 19px; } }
  .booklist__book-meta .tags {
    color: var(--gray-500);
    margin-top: 1em; }

.booklist__book-of-the-year,
.booklist__currently-reading {
  background-color: var(--yellow);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 11.5px;
  line-height: 19px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  --underline-offset: 1.5px;
  --underline-thickness: 0.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.4px;
  padding: 0.3em 0.8em 0.27em;
  vertical-align: 0.35em;
  border-radius: 1em;
  white-space: nowrap; }
  @media (max-width: 599px) {
    .booklist__book-of-the-year,
    .booklist__currently-reading {
      transform-origin: center left;
      transform: scale(0.9);
      display: block;
      max-width: max-content;
      flaot: left;
      clear: both;
      margin-top: 0.8em; } }

.booklist__currently-reading {
  background-color: var(--gray-100); }

.booklist__book-text {
  margin-top: calc(var(--ken) * -1); }
  .booklist__book-text .headline--4 {
    margin-top: calc(var(--ken) * 3); }

.bookshelf__meta-content {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 11.5px;
  line-height: 19px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  --underline-offset: 1.5px;
  --underline-thickness: 0.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.4px;
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .bookshelf__meta-content::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.bookshelf__meta-content--small {
  display: inline-block;
  margin-left: 1em;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px; }

.collection {
  background-color: var(--gray-050);
  --aside-text-color: var(--gray-700); }

.collection__top {
  padding-bottom: calc(var(--ken) * 8); }
  @media (max-width: 599px) {
    .collection__top {
      padding-bottom: calc(var(--ken) * 5); } }

.collection__title {
  padding-top: calc(var(--ken) * 18); }
  @media (max-width: 599px) {
    .collection__title {
      padding-top: calc(var(--ken) * 6); } }

.collection__title-content {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 106px;
  line-height: 90px;
  letter-spacing: -6px;
  --align-cap-height: -9px;
  --align-baseline: -9px;
  transform: translateY(calc(-1 * var(--align-baseline))); }
  @media (max-width: 599px) {
    .collection__title-content {
      font-size: 37px;
      line-height: 38px;
      letter-spacing: -1.3px;
      word-spacing: 4px; } }
  .collection__title-content::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.collection__meta {
  color: var(--aside-text-color);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  letter-spacing: 0.4px;
  word-spacing: 2px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1;
  transform: translateY(calc(-1 * var(--align-baseline)));
  display: block;
  margin-top: calc(var(--ken) * 3); }
  .collection__meta::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.collection__meta--small {
  display: inline-block;
  margin-left: 0.1em;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px; }

.collection__intro {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calc(var(--ken) * 5);
  margin-top: calc(var(--ken) * 7); }
  @media (max-width: 1200px) {
    .collection__intro {
      grid-template-columns: 1fr; } }

.collection__intro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 2px;
  --underline-thickness: 2px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 28em; }
  @media (max-width: 599px) {
    .collection__intro-text {
      font-size: 20px;
      line-height: 26px;
      --underline-thickness: 1.5px; } }
  .collection__intro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.collection__toc {
  transform: translateY(calc(-1 * var(--align-baseline)));
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px; }
  .collection__toc::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  @media (max-width: 599px) {
    .collection__toc {
      line-height: 19px; } }
  @media (max-width: 1200px) {
    .collection__toc {
      display: none; } }

.collection__toc-content {
  word-spacing: 12px; }
  .collection__toc-content a {
    line-height: calc(1em + 11px);
    word-spacing: 0;
    color: var(--gray-500);
    text-decoration-color: var(--gray-200); }
    .collection__toc-content a:hover {
      color: var(--black);
      text-decoration-color: var(--gray-300); }

.collection__content {
  padding-bottom: calc(var(--ken) * 8);
  grid-column: 3 / -4; }
  @media (max-width: 1200px) {
    .collection__content {
      grid-column: 3 / -3; } }

.collection__items {
  --longread-grid-columns: 2;
  --longread-grid-column-gap: calc(var(--ken) * 3);
  column-gap: var(--longread-grid-column-gap);
  row-gap: var(--longread-grid-column-gap);
  columns: var(--longread-grid-columns); }
  @media (max-width: 900px) {
    .collection__items {
      --longread-grid-columns: 1; } }

.collection-item {
  background-color: var(--white);
  padding: calc(var(--ken) * 2.5);
  align-self: start;
  counter-reset: collection-aside-counter aside-counter;
  break-inside: avoid;
  margin-top: 0;
  margin-bottom: calc(var(--longread-grid-column-gap) * 1); }
  .collection-item .aside-anchor {
    display: inline; }
  .collection-item .aside {
    display: none; }
  .collection-item:last-child {
    margin-bottom: 0; }
  @media (max-width: 599px) {
    .collection-item {
      padding: calc(var(--ken) * 1.5); } }

.collection-item__asides {
  margin-top: calc(var(--ken) * 5); }

.collection-item__aside {
  color: var(--gray-600);
  counter-increment: collection-aside-counter;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: calc(var(--ken) * 0.2);
  align-items: start; }
  .collection-item__aside::before {
    font-variant-numeric: tabular-nums;
    font-feature-settings: "zero" 1;
    content: "(" counter(collection-aside-counter) ")";
    transform: scale(0.8);
    line-height: inherit;
    vertical-align: bottom;
    transform-origin: center left; }

.collection-item__aside + .collection-item__aside {
  margin-top: calc(var(--ken) * 0.3); }

.collection__item-headline {
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .collection__item-headline::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.collection .default__read-more {
  background-color: var(--white); }

.collection .default__read-more-content.collection__literature {
  grid-column: 3 / 6;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px; }
  @media (max-width: 599px) {
    .collection .default__read-more-content.collection__literature {
      grid-column: 3 / -3;
      grid-row: 1;
      padding-right: 0;
      padding-top: calc(var(--ken) * 3);
      padding-bottom: calc(var(--ken) * 3); } }
  @media (max-width: 599px) {
    .collection .default__read-more-content.collection__literature {
      line-height: 19px; } }
  .collection .default__read-more-content.collection__literature ul {
    list-style: none; }
  .collection .default__read-more-content.collection__literature li {
    max-width: 38em;
    margin-bottom: 0.65em; }
    .collection .default__read-more-content.collection__literature li:last-child {
      margin-bottom: 0; }
  .collection .default__read-more-content.collection__literature a {
    color: var(--gray-800);
    text-decoration-color: var(--gray-200); }

.collection__literature-headline {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  margin-bottom: calc(var(--ken) * 2); }
  @media (max-width: 599px) {
    .collection__literature-headline {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }
  .collection__literature-headline::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.collection .default__read-more-content {
  grid-column: 3 / -4; }
  @media (max-width: 599px) {
    .collection .default__read-more-content {
      grid-column: 3 / -3; } }

.collection .collection__read-more-content--with-literature {
  grid-column: 6 / -4;
  padding-left: calc(var(--ken) * 5); }
  @media (max-width: 599px) {
    .collection .collection__read-more-content--with-literature {
      grid-column: 3 / -3;
      padding-left: 0; } }

.default__hero {
  grid-row: 1;
  margin-top: calc((var(--ken) * 5) - var(--frontmatter-margin-bottom));
  padding-bottom: calc(var(--ken) * 5); }
  @media (max-width: 599px) {
    .default__hero {
      margin-top: calc((var(--ken) * 3) - var(--frontmatter-margin-bottom));
      padding-bottom: calc(var(--ken) * 3); } }

.default__hero-picture-image {
  max-width: 100%;
  width: 100%; }

.default__tags {
  padding-top: calc(var(--ken) * 2.25); }
  .default__tags .tag:not(.tag--highlight) {
    color: var(--gray-500); }

.default__intro {
  padding-bottom: calc(var(--ken) * 5); }

.default__intro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: -0.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 2px;
  --underline-thickness: 2px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 28em; }
  @media (max-width: 599px) {
    .default__intro-text {
      font-size: 20px;
      line-height: 26px;
      --underline-thickness: 1.5px; } }
  .default__intro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.default__content {
  padding-bottom: calc(var(--ken) * 5); }

.default--with-footer .default__content {
  padding-bottom: calc(var(--ken) * 8); }

.default__text {
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .default__text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.default__read-more-content {
  padding-top: calc(var(--ken) * 5);
  padding-bottom: calc(var(--ken) * 5);
  grid-row: 2; }

.default__read-more-headline {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: -1.2px;
  word-spacing: 3px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 2px;
  --underline-thickness: 2.5px;
  letter-spacing: -0.9px;
  word-spacing: 3.5px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  margin-bottom: calc(var(--ken) * 4); }
  .default__read-more-headline::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  .default__read-more-headline .aside {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px; }
    @media (max-width: 599px) {
      .default__read-more-headline .aside {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  @media (min-width: 1000px) {
    .default__read-more-headline .aside {
      transform: translateY(0.76em) !important; } }

@media (max-width: 599px) {
  .home .header {
    position: absolute; } }

.home__logo {
  background-color: var(--blue-gray); }
  .home__logo::before {
    content: "";
    background-color: var(--black);
    grid-row: 1;
    grid-column: 1 / 6; }
    @media (max-width: 900px) {
      .home__logo::before {
        background-color: var(--blue-gray); } }

.home__logo-content {
  padding-top: calc(var(--ken) * 3);
  padding-bottom: calc(var(--ken) * 20);
  font-weight: 400;
  font-size: calc((var(--grid-col-width) * 5 - var(--main-col-padding-left) - var(--main-col-padding-right)) * 0.0917);
  line-height: 0.93em;
  margin-top: -0.12em;
  margin-bottom: -0.07em;
  color: #12161a; }
  @media (min-width: 1440px) {
    .home__logo-content {
      font-size: 63px; } }
  @media (max-width: 900px) {
    .home__logo-content {
      font-size: calc((var(--grid-col-width) * 8 - var(--main-col-padding-left) - var(--main-col-padding-right)) * 0.085); } }
  @media (max-width: 599px) {
    .home__logo-content {
      padding-top: calc(var(--ken) * 2);
      padding-bottom: calc(var(--ken) * 12);
      font-size: calc((var(--grid-col-width) * 8 - var(--main-col-padding-left) - var(--main-col-padding-right)) * 0.075); } }

.home__title {
  text-transform: uppercase;
  letter-spacing: -0.048em;
  word-spacing: 0.15em; }
  @media (max-width: 599px) {
    .home__title {
      letter-spacing: -0.04em; } }

.home__subtitle {
  letter-spacing: -0.06em;
  word-spacing: 0.03em; }
  @media (max-width: 599px) {
    .home__subtitle {
      letter-spacing: -0.05em; } }

.home__intro {
  --aside-text-color: var(--white);
  --aside-line-color: var(--white);
  --aside-light-text-color: rgba(255, 255, 255, 0.4); }
  .home__intro::before {
    content: "";
    background-color: var(--black);
    grid-row: 1;
    grid-column: 1 / 6; }
    @media (max-width: 900px) {
      .home__intro::before {
        background-color: var(--yellow); } }
  .home__intro::after {
    content: "";
    background-color: var(--yellow);
    grid-row: 1;
    grid-column: -4 / -1;
    z-index: -1; }

.home__intro-content {
  background-color: var(--yellow);
  padding-top: calc(var(--ken) * 4);
  padding-bottom: calc(var(--ken) * 4);
  grid-row: 1; }
  .home__intro-content p:first-child {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: -0.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    --underline-offset: 2px;
    --underline-thickness: 2px;
    max-width: 28em;
    margin-bottom: calc(var(--ken) * 2) !important; }
    @media (max-width: 599px) {
      .home__intro-content p:first-child {
        font-size: 20px;
        line-height: 26px;
        --underline-thickness: 1.5px; } }
  .home__intro-content .aside-anchor {
    color: rgba(0, 0, 0, 0.45); }
  @media (max-width: 599px) {
    .home__intro-content {
      padding-top: calc(var(--ken) * 3);
      padding-bottom: calc(var(--ken) * 4); } }

.home__read-more {
  --aside-text-color: var(--white);
  --aside-line-color: var(--white);
  --aside-light-text-color: rgba(255, 255, 255, 0.4); }
  .home__read-more::before {
    content: "";
    background-color: var(--black);
    grid-row: 1;
    grid-column: 1 / 6; }
    @media (max-width: 900px) {
      .home__read-more::before {
        background-color: var(--gray-800);
        grid-column: 1 / 3; } }
  .home__read-more::after {
    content: "";
    background-color: var(--gray-800);
    grid-row: 1;
    grid-column: -4 / -1;
    z-index: -1; }
    @media (max-width: 599px) {
      .home__read-more::after {
        grid-column: -3 / -1; } }

.home__read-more-content {
  background-color: var(--gray-800);
  color: var(--white);
  padding-top: calc(var(--ken) * 1.5);
  padding-bottom: calc(var(--ken) * 1.6);
  grid-row: 1; }

h3.home__intro-links-title {
  margin-top: calc(var(--ken) * 4);
  margin-bottom: calc(var(--ken) * 0.5);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 9.5px;
  line-height: 13px;
  letter-spacing: 0.2px;
  word-spacing: -2px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.6); }

.home__intro-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: calc(var(--ken) * 2);
  row-gap: calc(var(--ken) * 0.5); }
  .home__intro-links .arrow {
    color: rgba(255, 255, 255, 0.3);
    vertical-align: -2px; }
  .home__intro-links a {
    text-decoration: none; }

.home__notes {
  --aside-text-color: var(--white);
  --aside-line-color: var(--white);
  --aside-light-text-color: rgba(255, 255, 255, 0.4); }
  .home__notes::before {
    content: "";
    background-color: var(--black);
    grid-row: 1 / 3;
    grid-column: 1 / 6; }
    @media (max-width: 900px) {
      .home__notes::before {
        background-color: var(--yellow); } }

.home__notes-content {
  padding-top: calc(var(--ken) * 6);
  padding-bottom: calc(var(--ken) * 3); }
  @media (max-width: 599px) {
    .home__notes-content {
      padding-top: calc(var(--ken) * 5);
      padding-bottom: calc(var(--ken) * 0); } }

.home__notes-headline {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 32px;
  line-height: 35px;
  letter-spacing: -1.2px;
  word-spacing: 3px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  --underline-offset: 2px;
  --underline-thickness: 2.5px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  margin-bottom: calc(var(--ken) * 4); }
  .home__notes-headline::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  .home__notes-headline .aside {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px; }
    @media (max-width: 599px) {
      .home__notes-headline .aside {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }
  @media (min-width: 1000px) {
    .home__notes-headline .aside {
      transform: translateY(0.76em) !important; } }
  @media (max-width: 599px) {
    .home__notes-headline {
      margin-bottom: calc(var(--ken) * 3); } }

.home__outro-content {
  margin-top: calc(var(--ken) * 7);
  transform: translateY(calc(-1 * var(--align-baseline))); }
  .home__outro-content::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  @media (max-width: 599px) {
    .home__outro-content {
      margin-top: calc(var(--ken) * 9); } }

.home .footer__content {
  padding-bottom: calc(var(--ken) * 3); }
  @media (max-width: 599px) {
    .home .footer__content {
      padding-bottom: calc(var(--ken) * 1.5); } }

.home__spinning {
  animation: rotating 8s linear infinite;
  display: inline-block; }

@keyframes rotating {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.map__intro {
  margin-top: calc((var(--ken) * 4) - var(--frontmatter-margin-bottom));
  padding-bottom: calc(var(--ken) * 6);
  --annotation-left-buffer: var(--main-col-padding-left); }

.map__intro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 32em; }
  @media (max-width: 599px) {
    .map__intro-text {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }
  .map__intro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.map__vis-loading {
  height: 0; }

.map__outro {
  margin-top: calc(var(--ken) * 6);
  padding-bottom: calc(var(--ken) * 5);
  --annotation-left-buffer: var(--main-col-padding-left); }

.map__outro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 32em; }
  @media (max-width: 599px) {
    .map__outro-text {
      line-height: 19px; } }
  .map__outro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.map__loading {
  margin-top: calc(var(--ken) * 15);
  transform: rotate(45deg); }
  .map__loading text {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 17px;
    line-height: 24.25px;
    letter-spacing: -0.3px;
    --underline-offset: 1.5px;
    --align-cap-height: -6px;
    --align-baseline: -6px;
    letter-spacing: -0.1px;
    word-spacing: 1px; }
    @media (max-width: 599px) {
      .map__loading text {
        font-size: 16px;
        line-height: 23.5px;
        --underline-offset: 2px; } }

.map__vis-container {
  max-width: 100vw;
  overflow-x: clip;
  overflow-y: visible; }

.map__vis {
  width: 100%;
  height: 1000px;
  grid-column: 3 / -3;
  position: relative; }
  @media (max-width: 1200px) {
    .map__vis {
      height: 1200px; } }
  @media (max-width: 599px) {
    .map__vis {
      height: 1400px; } }
  .map__vis .links {
    position: absolute;
    top: -250px;
    right: -250px;
    bottom: -250px;
    left: -250px;
    pointer-events: none;
    z-index: 1; }
  .map__vis .blurs {
    position: absolute;
    top: -250px;
    right: -250px;
    bottom: -250px;
    left: -250px;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    transition: all 1s; }
  .map__vis .link {
    fill: none;
    transition: all 1s;
    stroke: #e6e6e6; }
  .map__vis .node {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 5; }
  .map__vis .node--fixed {
    display: none; }
  .map__vis .node .node__body {
    line-height: 1em;
    background-color: white;
    z-index: 10;
    border: 1px solid var(--black);
    display: block;
    padding: 0.4em 0.6em;
    position: relative;
    transition: opacity 1s;
    transform: translate(-50%, -50%) scale(var(--scale)); }
  .map__vis .node--movement .node__body {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 11.1px;
    line-height: 15px;
    --align-cap-height: -3.5px;
    --align-baseline: -3.5px;
    --underline-offset: 2px;
    letter-spacing: 0.4px;
    word-spacing: 2px;
    line-height: 1em;
    padding: 0.35em 0.8em 0.3em;
    border-radius: 2em; }
  .map__vis .node--person .node__body {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-mono);
    font-size: 9.5px;
    line-height: 13px;
    letter-spacing: 0.2px;
    word-spacing: -2px;
    line-height: 1em;
    padding: 0.27em 0.6em;
    border-radius: 2em;
    background-color: var(--black);
    color: var(--white); }

.map__vis--hover .blurs {
  opacity: 0.7; }

.map__vis--hover .node .node__body {
  opacity: 0.05; }

.map__vis--hover .node--hover-3 .node__body {
  opacity: 0.15; }

.map__vis--hover .node--hover-2 .node__body {
  opacity: 0.3; }

.map__vis--hover .node--hover-1 .node__body {
  opacity: 1; }

.map__vis--hover .node--hover .node__body {
  opacity: 1; }

.map__vis--hover .link {
  opacity: 0; }

.map__vis--hover .link--hover-2 {
  opacity: 0;
  stroke: black; }

.map__vis--hover .link--hover-1 {
  opacity: 0;
  stroke: black; }

.map__vis--hover .link--hover {
  opacity: 1;
  stroke: black; }

.notes__content {
  grid-column: 3 / 8;
  padding-bottom: calc(var(--ken) * 5);
  padding-right: calc(var(--ken) * 2); }

.notes__subtitle {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  margin-top: calc(var(--ken) * 2.5);
  margin-bottom: calc(var(--ken) * 0.5); }
  @media (max-width: 599px) {
    .notes__subtitle {
      line-height: 19px; } }
  .notes__subtitle:first-child {
    margin-top: 0; }

.notes__filtering-annotation-tag {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 11.1px;
  line-height: 15px;
  --align-cap-height: -3.5px;
  --align-baseline: -3.5px;
  --underline-offset: 2px;
  letter-spacing: 0.4px;
  word-spacing: 2px; }

.notes .notes-list {
  margin-top: calc(var(--ken) * 1.5); }

.notes__permanent {
  grid-column: 8 / -3;
  padding-left: calc(var(--ken) * 2);
  padding-bottom: calc(var(--ken) * 5);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.notes__permanent-content {
  position: sticky;
  top: calc(var(--ken) * 12);
  margin-bottom: calc(var(--ken) * 10); }

.notes__permanent-links {
  margin-top: calc(var(--ken) * 0.4);
  position: relative; }
  .notes__permanent-links::after {
    content: "";
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    right: -0.5px;
    bottom: -0.5px;
    border: 1px solid var(--black);
    pointer-events: none; }

.notes__permanent-links-large {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%; }

.notes__permanent-links-small {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%; }

.notes__permanent-link {
  display: block;
  text-decoration: none;
  padding: calc(var(--ken) * 1) calc(var(--ken) * 1.2);
  position: relative; }
  .notes__permanent-link::after {
    content: "";
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    right: -0.5px;
    bottom: -0.5px;
    border: 1px solid var(--black);
    pointer-events: none; }

.notes__permanent-link-intro {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  line-height: 1.3em;
  margin-top: calc(var(--ken) * 0.25); }
  @media (max-width: 599px) {
    .notes__permanent-link-intro {
      line-height: 19px; } }

.notes__permanent-link-bookshelf {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  letter-spacing: -0.1px;
  word-spacing: 1px;
  grid-column: span 3;
  padding-bottom: calc(var(--ken) * 2); }
  @media (max-width: 599px) {
    .notes__permanent-link-bookshelf {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.notes__permanent-link-map {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  letter-spacing: -0.1px;
  word-spacing: 1px;
  grid-column: span 3;
  padding-bottom: calc(var(--ken) * 2); }
  @media (max-width: 599px) {
    .notes__permanent-link-map {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.notes__permanent-link--collection {
  grid-column: span 2;
  line-height: 1.3em; }
  .notes__permanent-link--collection .notes__permanent-link-intro {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: none;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 11.1px;
    line-height: 15px;
    --align-cap-height: -3.5px;
    --align-baseline: -3.5px;
    --underline-offset: 2px;
    line-height: 1.3em;
    margin-top: calc(var(--ken) * 0.3); }

.notes__tags {
  margin-top: calc(var(--ken) * 2.5); }
  @media (max-width: 599px) {
    .notes__tags {
      display: none; } }

.notes__subscribe {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  max-width: 30em; }
  @media (max-width: 599px) {
    .notes__subscribe {
      line-height: 19px; } }

.notes__box-text {
  margin-top: calc(var(--ken) * 20); }
  .notes__box-text text {
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0px;
    word-spacing: 0;
    --underline-offset: auto;
    --underline-thickness: auto;
    --align-cap-height: 0;
    --align-baseline: 0;
    text-transform: uppercase;
    font-family: var(--font-family-sans);
    font-feature-settings: "zero" 1;
    font-variant-numeric: tabular-nums;
    font-size: 11.1px;
    line-height: 15px;
    --align-cap-height: -3.5px;
    --align-baseline: -3.5px;
    --underline-offset: 2px;
    letter-spacing: 0.4px;
    word-spacing: 2px; }

@media (max-width: 900px) {
  .notes__content {
    grid-column: 3 / -4; }
  .notes__permanent {
    grid-column: 3 / -4;
    padding-left: 0;
    padding-bottom: calc(var(--ken) * 5); }
  .notes__permanent-content {
    position: static;
    top: unset; }
  .notes__box-text {
    margin-top: calc(var(--ken) * 5); } }

@media (max-width: 599px) {
  .notes__content {
    grid-column: 3 / -3;
    padding-right: 0;
    grid-row: 2; }
  .notes__permanent {
    grid-column: 3 / -3;
    grid-row: 1; }
  .notes__permanent-link-bookshelf {
    grid-column: span 6; }
  .notes__permanent-link-map {
    grid-column: span 6; }
  .notes__permanent-link--collection {
    grid-column: span 3; }
  .notes__box-text {
    margin-top: calc(var(--ken) * 5);
    display: none; } }

@media (min-width: 900px) {
  .travel {
    --frontmatter-margin-bottom: calc(var(--ken) * 18); } }

.travel .frontmatter__title {
  padding-bottom: var(--frontmatter-margin-bottom); }

.travel .frontmatter__title-content {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  max-width: 40em;
  line-height: 20px;
  margin-top: 2px; }
  @media (max-width: 599px) {
    .travel .frontmatter__title-content {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

.travel__text {
  transform: translateY(calc(-1 * var(--align-baseline)));
  margin-bottom: calc(var(--ken) * 10); }
  .travel__text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  @media (max-width: 599px) {
    .travel__text {
      margin-bottom: calc(var(--ken) * 8); } }

.travel__repository {
  margin-bottom: calc(var(--ken) * 6);
  grid-column: 3 / -4;
  padding-right: 0; }
  @media (min-width: 900px) {
    .travel__repository {
      grid-column: 3 / 10;
      padding-right: calc(var(--ken) * 5); } }
  @media (max-width: 599px) {
    .travel__repository {
      grid-column: 3 / -3;
      padding-right: 0;
      margin-bottom: calc(var(--ken) * 0); } }

.travel__repository-headline {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);
  font-size: 19px;
  line-height: 29px;
  letter-spacing: -0.3px;
  word-spacing: -5px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  margin-bottom: calc(var(--ken) * 3); }
  @media (max-width: 599px) {
    .travel__repository-headline {
      font-size: 18px;
      line-height: 27px; } }
  .travel__repository-headline::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }
  .travel__repository-headline .headline-link {
    user-select: none;
    text-decoration: none;
    opacity: 0;
    transition: opacity 0.5s;
    break-before: avoid;
    white-space: nowrap; }
  .travel__repository-headline:hover .headline-link {
    opacity: 0.3;
    transition: opacity 0.5s 1s; }

.travel .default__read-more-content {
  grid-column: 3 / -4;
  padding-right: 0; }
  @media (min-width: 900px) {
    .travel .default__read-more-content {
      grid-column: 3 / 10;
      padding-right: calc(var(--ken) * 5); } }
  @media (max-width: 599px) {
    .travel .default__read-more-content {
      grid-column: 3 / -3;
      padding-right: 0; } }

@media (min-width: 900px) {
  .recommendations .grid::before {
    display: none; }
  .recommendations {
    --aside-text-color: var(--gray-700);
    --aside-light-text-color: var(--gray-400); }
  .recommendations .longread--large .aside--outside {
    --aside-text-color: var(--black); } }

.recommendations .repository {
  column-rule: 1px solid var(--gray-100);
  column-gap: 0.5px;
  border-bottom: 1px solid var(--gray-100);
  margin-left: calc(var(--ken) * -2);
  margin-right: calc(var(--ken) * -2);
  margin-bottom: 0;
  position: relative; }
  @media (max-width: 599px) {
    .recommendations .repository {
      margin-left: calc(var(--ken) * -1.5);
      margin-right: calc(var(--ken) * -1.5); } }
  .recommendations .repository::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    bottom: -1px;
    width: calc(var(--ken) * 2);
    background-color: white; }
    @media (max-width: 599px) {
      .recommendations .repository::before {
        width: calc(var(--ken) * 1.5); } }
  .recommendations .repository::after {
    content: "";
    position: absolute;
    top: -1px;
    right: 0;
    bottom: -1px;
    width: calc(var(--ken) * 2);
    background-color: white; }
    @media (max-width: 599px) {
      .recommendations .repository::after {
        width: calc(var(--ken) * 1.5); } }

.recommendations .repository__category {
  padding-right: calc(var(--ken) * 3);
  padding-left: calc(var(--ken) * 2);
  padding-top: calc(var(--ken) * 2);
  padding-bottom: calc(var(--ken) * 3);
  margin-bottom: calc(var(--ken) * 0);
  border-top: 1px solid var(--gray-100); }
  @media (max-width: 599px) {
    .recommendations .repository__category {
      padding-right: calc(var(--ken) * 2);
      padding-left: calc(var(--ken) * 1.5); } }

.recommendations__intro {
  margin-top: calc((var(--ken) * 4) - var(--frontmatter-margin-bottom));
  padding-bottom: calc(var(--ken) * 5);
  --annotation-left-buffer: var(--main-col-padding-left); }

.recommendations__intro-text {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  transform: translateY(calc(-1 * var(--align-baseline)));
  max-width: 32em; }
  @media (max-width: 599px) {
    .recommendations__intro-text {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }
  .recommendations__intro-text::before {
    content: "";
    margin-top: calc(var(--align-cap-height) + var(--align-baseline));
    display: block;
    height: 0; }

.recommendations__intro-links {
  margin-top: calc(var(--ken) * 1.2);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  color: var(--gray-200);
  word-spacing: -0px;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "zero" 1; }
  @media (max-width: 599px) {
    .recommendations__intro-links {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }
  .recommendations__intro-links .recommendations__intro-link {
    color: var(--gray-500);
    text-decoration: none;
    white-space: nowrap; }
    .recommendations__intro-links .recommendations__intro-link:hover {
      text-decoration: underline; }
  @media (max-width: 599px) {
    .recommendations__intro-links {
      margin-top: calc(var(--ken) * 1.5);
      line-height: 24px; } }

.recommendations__city {
  margin-bottom: calc(var(--ken) * 14);
  padding-top: calc(var(--ken) * 1);
  border-top: 1px solid black; }
  .recommendations__city:last-child {
    margin-bottom: 0; }
  .recommendations__city:first-child {
    margin-top: calc(var(--ken) * 10); }
    @media (max-width: 599px) {
      .recommendations__city:first-child {
        margin-top: calc(var(--ken) * 6); } }
  @media (max-width: 599px) {
    .recommendations__city {
      margin-bottom: calc(var(--ken) * 12); } }

.recommendations__city-intro {
  margin-bottom: calc(var(--ken) * 6); }
  @media (max-width: 599px) {
    .recommendations__city-intro {
      margin-bottom: calc(var(--ken) * 5); } }

.recommendations__city-head {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--ken) * 3);
  align-items: end;
  justify-content: space-between; }
  @media (max-width: 599px) {
    .recommendations__city-head {
      gap: calc(var(--ken) * 1.5); } }

.recommendations__city-headline {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 106px;
  line-height: 90px;
  letter-spacing: -6px;
  --align-cap-height: -9px;
  --align-baseline: -9px;
  hyphens: auto;
  max-width: 100%; }
  @media (max-width: 599px) {
    .recommendations__city-headline {
      font-size: 37px;
      line-height: 38px;
      letter-spacing: -1.3px;
      word-spacing: 4px; } }

.recommendations__city-location {
  text-decoration: none; }
  @media (max-width: 900px) {
    .recommendations__city-location {
      display: none; } }

.recommendations__city-location .location {
  color: var(--red);
  white-space: nowrap; }

.recommendations__city-meta {
  transform: translateY(-0.2em);
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: calc(var(--ken) * 0); }
  @media (max-width: 900px) {
    .recommendations__city-meta {
      transform: translateY(0em);
      color: var(--red); } }

.recommendations__city-date {
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  white-space: nowrap; }
  @media (max-width: 599px) {
    .recommendations__city-date {
      line-height: 19px; } }

.recommendations__city-link {
  display: block;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: uppercase;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  --underline-offset: 1.5px;
  --align-cap-height: -5px;
  --align-baseline: -5px;
  letter-spacing: 0.3px;
  word-spacing: 1px;
  color: var(--gray-800);
  margin-top: calc(var(--ken) * 1.5); }
  @media (max-width: 599px) {
    .recommendations__city-link {
      line-height: 19px; } }
  @media (max-width: 599px) {
    .recommendations__city-link {
      margin-top: calc(var(--ken) * 2); } }
  .recommendations__city-link .arrow {
    color: var(--gray-300);
    vertical-align: -2px; }

*,
*::after,
*::before {
  box-sizing: border-box; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  font-family: var(--font-family-sans);
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0px;
  word-spacing: 0;
  --underline-offset: auto;
  --underline-thickness: auto;
  --align-cap-height: 0;
  --align-baseline: 0;
  text-transform: none;
  font-family: var(--font-family-sans);
  font-feature-settings: "zero" 1;
  font-variant-numeric: tabular-nums;
  font-size: 17px;
  line-height: 24.25px;
  letter-spacing: -0.3px;
  --underline-offset: 1.5px;
  --align-cap-height: -6px;
  --align-baseline: -6px;
  hyphens: manual;
  -webkit-hyphens: manual;
  scroll-padding-top: calc(var(--ken) * 3); }
  @media (max-width: 599px) {
    html,
    body {
      font-size: 16px;
      line-height: 23.5px;
      --underline-offset: 2px; } }

h1,
h2,
h3,
h4,
ul {
  margin: 0;
  padding: 0; }

p {
  margin: 0; }

a,
.link {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  text-underline-offset: var(--underline-offset);
  text-decoration-thickness: var(--underline-thickness);
  transition: all 0.2s;
  --link-color: var(--color-gray-100);
  --link-text-color: currentColor; }
  a:active,
  .link:active {
    opacity: 0.7; }
  a--minimal:not(:hover),
  .link--minimal:not(:hover) {
    text-decoration: none; }
  a--bullet,
  .link--bullet {
    text-decoration: none;
    background-color: var(--link-color);
    color: var(--link-text-color);
    border: 2px solid var(--link-color);
    border-radius: 1em;
    padding: 0.03em 0.42em; }
    a--bullet:hover,
    .link--bullet:hover {
      background-color: transparent;
      color: currentColor; }
    @media (max-width: 599px) {
      a--bullet,
      .link--bullet {
        padding: 0.01em 0.42em; } }

main {
  min-height: 100vh; }
  @media (max-width: 599px) {
    main {
      min-height: unset; } }
